<template>
  <v-row>
    <template
      v-for="card in cards"
    >
      <v-col cols="12" lg="3" sm="6" :key="card.title" v-if="card.show" class="py-0 mb-7"  :revenuecard="card">
        <v-card>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn :class="[card.iconcolor]" class="elevation-0 mr-2" fab dark>
                <v-icon>{{ card.icon }}</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <v-progress-linear
                  v-show="isLoading"
                  :color="card.iconcolor"
                  indeterminate
                  rounded
                  height="8"
                ></v-progress-linear>
                <h1 class="letter-spacing mb-1 mt-2 font-weight-bold">
                  {{ card.number }}
                </h1>
                <h5 class="body-1 font-weight-regular mt-1">
                  {{ card.title }}
                </h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import ReportService from "../../services/pas-report";
import { convertPriceString } from "../../plugins/helper";
import i18n from "@/plugins/i18n";

export default {
  data: () => ({
    isLoading: true,
    cards: [
      {
        title: i18n.t('Revenue.TotalRevenue'),
        iconcolor: "info",
        icon: "ti-wallet",
        number: "",
        show: false
      },
      {
        title: i18n.t('Revenue.TotalAdmissions'),
        iconcolor: "orange",
        icon: "mdi-book",
        number: "",
        show: true
      },
      {
        title: i18n.t('Revenue.TotalActivePatients'),
        iconcolor: "indigo",
        icon: "mdi-account-box",
        number: "",
        show: true
      },
      {
        title: i18n.t('Revenue.TotalActiveStaffs'),
        iconcolor: "cyan",
        icon: "mdi-clipboard-account",
        number: "",
        show: true
      },
    ],
  }),
  created() {
    this.render();
  },
  methods: {
    async render() {
      this.isLoading = true;
      var result = await ReportService.getGeneralStatistics();
      this.isLoading = false;
      if (!result) this.$emit("onError", "Failed to load general statistics!");
      var {
        countTotalPatients,
        countTotalAdmissions,
        countTotalRevenues,
        countTotalStaffs,
      } = result;
      this.cards[0].number = convertPriceString(countTotalRevenues);
      this.cards[1].number = countTotalAdmissions;
      this.cards[2].number = countTotalPatients;
      this.cards[3].number = countTotalStaffs;
    },
  },
};
</script>
<style scoped>
.letter-spacing {
  letter-spacing: 0.5px;
}
</style>